import axios from "axios";
import config from "../config.json";

export const read_cookie = (name: string) => {
  // Escape special characters in the cookie name
  const escapedName = name.replace(/([.*+?^${}()|[\]\\])/g, '\\$1');
  const cookieValue = document.cookie.match(new RegExp(`(?:^|; )${escapedName}=([^;]*)`));

  if (cookieValue) {
    try {
      const [, value] = cookieValue;
      return decodeURIComponent(value); // Decode the cookie value
    } catch (error) {
      console.error(`Error reading the '${name}' cookie:`, error);
    }
  } else {
    console.warn(`Cookie '${name}' not found.`);
  }

  return null;
};

const apiUrl =
  process.env.NODE_ENV === "production"
    ? config.production.ZOOZA_URL
    : config.development.ZOOZA_URL;
const apiKey =
  process.env.NODE_ENV === "production"
    ? config.production.ZOOZA_API_KEY
    : config.development.ZOOZA_API_KEY;
let zoozaCompany =
  read_cookie("active_company") || read_cookie("active_company-legacy") || 1;

const token = read_cookie("token") || read_cookie("token-legacy");
const headers = {
  "Content-Type": "application/json",
  "X-ZOOZA-API-KEY": apiKey,
  "X-ZOOZA-COMPANY": zoozaCompany,
  "X-ZOOZA-TOKEN": token,
};

export const ZoozaApi = axios.create({
  baseURL: apiUrl,
  headers: headers,
});
export const bake_cookie = (name: string, value: string | number) => {
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);
  if (window.location.hostname === "localhost") {
    const cookie = [
      name,
      "=",
      value,
      "; domain=",
      "localhost",
      "; path=/;",
      "expires=",
      expirationDate.toUTCString(),
    ].join("");
    document.cookie = cookie;
  } else {
    const cookie = [
      name,
      "=",
      value,
      "; domain=",
      window.location.host.toString(),
      "; path=/;",
      "expires=",
      expirationDate.toUTCString(),
    ].join("");
    document.cookie = cookie;
  }
};
export const delete_cookie = (name: string) => {
  document.cookie =
    name +
    "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=" +
    window.location.hostname +
    "; path=/;";
};

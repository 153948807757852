import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import i18n from "./locale"; // Import the i18n instance
import { I18nextProvider } from "react-i18next";
import { ZoozaProvider } from "./services/zooza.context";

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <ZoozaProvider>
      <HashRouter>
        <I18nextProvider i18n={i18n}>

            <App />

        </I18nextProvider>
      </HashRouter>
    </ZoozaProvider>
  );
}

import {EventDetail} from "../../types/schedule-type";
import React, {useContext} from "react";
import {ZoozaContextTypes} from "../../types/types";
import {ZoozaContext} from "../../services/zooza.context";
import {EventContextTypes} from "../../types/event-context-types";
import {EventContext} from "./event-context";
import {ZoozaCard, ZoozaCardActions, ZoozaCardHeader} from "../../components/zooza-card-components";
import {t} from "i18next";
import {Loading} from "../../components/loading";
import {AttendanceOpenCourse} from "./open-attendence-manager-component";
import {AttendanceRow} from "./attendance-row-component";

export const AttendanceCard = ({event}: {
    event?: EventDetail,

}) => {
    const {courses} = useContext<ZoozaContextTypes>(ZoozaContext)
    const {attendance, registrations} = useContext<EventContextTypes>(EventContext);


    const eventCourse = courses?.find((c) => c.id === event?.course_id);

    return (

        <ZoozaCard>
            <ZoozaCardHeader
                title={t("global.title.attendance")}
                icon="icon-tick-2"
            ></ZoozaCardHeader>
            {!eventCourse ? (
                <Loading/>
            ) : eventCourse?.registration_type === "open" && registrations.length !== 0 ? (
                registrations.map((reg, i) => {
                    return (
                        <>

                            <AttendanceOpenCourse
                                key={i}
                                event={event}
                                reg={reg}
                            />
                        </>

                    );
                })
            ) : (
                attendance?.map((a, i) => {
                    return (
                        <AttendanceRow attendance={a}/>
                    );
                })
            )}
            <ZoozaCardActions>{""}</ZoozaCardActions>
        </ZoozaCard>
    )
}

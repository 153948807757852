import React, {useContext, useState} from 'react'
import {DateTimeCard} from "./date-time-card";
import {ZoozaApi} from "../../services/Axios";
import {handleError} from "../../services/common-functions";
import {Link, useLocation, useParams} from "react-router-dom";
import {AddPaymentModal} from "../../components/add-payment-modal";
import {EventContextTypes} from "../../types/event-context-types";
import {EventContext} from "./event-context";
import {Modal} from "../../components/modal";
import {useTranslation} from "react-i18next";
import {AttendanceCard} from "./attendance-card";
import {t} from "i18next";
import moment from "moment/moment";

export const EventPage = () => {
    const {
        setOpenPaymentModal,
        openPaymentModal,
        ids,
        event,
        schedules,
        setEvent
    } = useContext<EventContextTypes>(EventContext);


    const location = useLocation();
    const getAdjacentEvents = () => {
        if (!schedules?.events || !event?.date)
            return {upComingEvent: undefined, pastEvent: undefined};

        const sortedEvents = [...schedules.events].sort(
            (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf()
        );

        const currentIndex = sortedEvents.findIndex((e) => e.id === event.id);

        if (currentIndex === -1)
            return {upComingEvent: undefined, pastEvent: undefined};

        return {
            pastEvent: currentIndex > 0 ? sortedEvents[currentIndex - 1] : undefined,
            upComingEvent:
                currentIndex < sortedEvents.length - 1
                    ? sortedEvents[currentIndex + 1]
                    : undefined,
        };
    };
    const {upComingEvent, pastEvent} = getAdjacentEvents();


    return (
        <div className="z2 app_page_layout">
            <div className="z2 app_page_toolbar">
                <Link
                    to={
                        pastEvent !== undefined
                            ? `/calendar/${pastEvent?.id}`
                            : location.pathname
                    }
                    role="button"
                    className={"z2 " + (pastEvent === undefined ? " disabled" : "")}
                >
                    {t("event.button.past_event")}
                    {pastEvent && " " + moment(pastEvent.date).format("DD.MM.YYYY")}
                </Link>
                <Link
                    to={
                        upComingEvent !== undefined
                            ? `/calendar/${upComingEvent?.id}`
                            : location.pathname
                    }
                    role="button"
                    className={"z2" + (upComingEvent === undefined ? " disabled" : "")}
                >
                    {t("event.button.next_event")}
                    {upComingEvent &&
                        " " + moment(upComingEvent.date).format("DD.MM.YYYY")}
                </Link>
            </div>
            <div className="app_page_column span_1">
                <DateTimeCard event={event} setEvent={setEvent} schedules={schedules}/>
            </div>
            <AttendanceCard event={event}/>
            <AddPaymentModal open={openPaymentModal} setOpen={setOpenPaymentModal} order_id={ids?.registration_id}
                             type="payment" order_type={"course"}/>
            <VoucherModal/>
            <NoVoucherModal/>
        </div>
    );
};

const VoucherModal = () => {
    const {
        openUseVoucherModal,
        setOpenUseVoucherModal,
        ids,
        getAttendance
    } = useContext<EventContextTypes>(EventContext);
    const {id} = useParams();
    const {t} = useTranslation();

    const handleUseVoucher = async () => {
        try {
            await ZoozaApi.put(`/registrations/${ids?.registration_id}`, {
                action: "set_attendance",
                attendance: "going",
                user_id: ids?.user_id,
                event_id: id,
                use_voucher: true,
            });
            setOpenUseVoucherModal(false);
            getAttendance();
        } catch (error: any) {
            handleError(error.response.data);
        }
    };

    return (
        <Modal
            submitButton={t("global.title.yes")}
            cancelButton={t("global.title.no")}
            title={t("event.title.want_use_voucher")}
            isOpen={openUseVoucherModal}
            onClose={() => setOpenUseVoucherModal(false)}
            onSubmit={handleUseVoucher}
        >
            <p>{t("event.title.this_client_has_available_entrances_from_the_entry_pass")}</p>
        </Modal>
    );
};

const NoVoucherModal = () => {
    const {
        openNoVoucherModal,
        setOpenNoVoucherModal,
        setOpenPaymentModal,
        ids,
        getAttendance
    } = useContext<EventContextTypes>(EventContext);
    const {t} = useTranslation();
    const [smallLoader, setSmallLoader] = useState(false);

    const setAttendance = async (type: "now" | "later") => {
        setSmallLoader(true);
        try {
            // Update the attendance status
            await ZoozaApi.put(`/registrations/${ids?.registration_id}`, {
                action: "set_attendance",
                attendance: "going",
                user_id: ids?.user_id,
                event_id: ids?.event_id,
                use_voucher: false,
            });

            // Close the modal
            setOpenNoVoucherModal(false);
            if (type === "now") {
                // Open the payment modal
                setOpenPaymentModal(true);
            }
            setSmallLoader(false);
            getAttendance();
        } catch (error) {
            setSmallLoader(false);
            console.error("Error updating attendance:", error);
        }
    };

    return (
        <Modal
            submitButton={t("sign_in.button.pay_now")}
            cancelButton={t("sign_in.button.pay_later")}
            title={t("event.title.pay_now_or_later")}
            isOpen={openNoVoucherModal}
            onClose={() => setAttendance("later")}
            onSubmit={() => setAttendance("now")}
            setOpen={setOpenNoVoucherModal}
        >
            <p style={{padding: 10}}>
                {t("event.title.this_event_not_free")}
            </p>
        </Modal>
    );
};

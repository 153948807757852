import React, {useContext, useEffect, useState} from "react";
import {Modal} from "./modal";

import {DateTimePicker} from "./date-picker";
import moment, {Moment} from "moment";
import {ZoozaApi} from "../services/Axios";
import {ErrorZozoaType, handleError, msg} from "../services/common-functions";
import {convertToCurrency} from "../services/curency-service";
import {ZoozaContext} from "../services/zooza.context";
import {ZoozaContextTypes} from "../types/types";
import {coursePaymentTypes, discountTypes, feePaymentTypes, Payment, paymentTypes,} from "../types/payments-types";
import {get_balance} from "../pages/payment-page/payment-status-component";
import {useTranslation} from "react-i18next";
import {AxiosError} from "axios";

export const AddPaymentModal = ({
                                    open,
                                    setOpen,
                                    order_id,
                                    type,
                                    order_type,
                                    onclick,
                                    default_value
                                }: {
    open: boolean;
    setOpen: (open: boolean) => void;
    order_id: number | string | undefined;
    type: "payment" | "discount" | "fee" | "refund" | "course_payment";
    order_type: "course" | "product";
    onclick?: any;
    default_value?: number;
}) => {
    const {t} = useTranslation();
    const {userData} = useContext<ZoozaContextTypes>(ZoozaContext);
    const [paymentType, setPaymentType] = useState("");
    const [value, setValue] = useState<number | string>(default_value || 0);
    const [paymentDate, setPaymentDate] = useState<Moment>(moment());
    const [note, setNote] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [payments, setPayments] = useState<Payment[]>([]);

    const countPayments = (payment_type: String[] | readonly string[]) => {
        const paymentsTemp = payments?.filter((payment) =>
            payment_type.includes(payment.transaction_type)
        );
        return paymentsTemp?.reduce((acc, payment) => {
            if (payment.amount) {
                return get_balance(
                    Number(acc),
                    Number(payment.amount),
                    Boolean(payment.amount)
                );
            } else return acc;
        }, 0);
    };

    const alreadyPayed = countPayments(paymentTypes);
    const alreadyDiscountPaid = Math.abs(countPayments(discountTypes));
    const alreadyCoursePaymentPaid = Math.abs(countPayments(coursePaymentTypes));
    const alreadyFeePaymentPaid = Math.abs(countPayments(feePaymentTypes));

    const toPay = Math.abs(
        alreadyPayed -
        alreadyCoursePaymentPaid -
        alreadyFeePaymentPaid +
        alreadyDiscountPaid
    );

    const differencePaid = Number(value) - Number(toPay);
    const differenceCoursePaid = Number(alreadyCoursePaymentPaid) - Number(value);
    const differenceFeePaid = Number(alreadyFeePaymentPaid) - Number(value);
    const differenceDiscountPaid = Number(value) - Number(alreadyDiscountPaid);

    const difference =
        type === "payment"
            ? Number(differencePaid)
            : type === "discount"
                ? Number(differenceDiscountPaid)
                : type === "fee"
                    ? Number(differenceFeePaid)
                    : type === "course_payment"
                        ? Number(differenceCoursePaid)
                        : "";
    const alowedToRefund = ["owner", "main_member", "member"];
    const alowedToPay = [
        "owner",
        "main_member",
        "member",
        "assistant",
        "receptionist",
    ];
    const allowToCorrect = ["owner"];
    const isAllowedToPay = alowedToPay.includes(String(userData?.user?.role));
    const isAllowedToRefund = alowedToRefund.includes(
        String(userData?.user?.role)
    );
    const isAllowedToCorrect = allowToCorrect.includes(
        String(userData?.user?.role)
    );
    const onSubmit = async () => {
        setIsSubmitting(true);

        if (!isAllowedToPay && paymentType === "credit") {
            alert(t("alert.error.add_payment.not_allow_to_pay"));
            return;
        }
        if (!isAllowedToRefund && paymentType === "refund") {
            alert(t("alert.error.add_payment.not_allowed_to_refund"));
            return;
        }
        if (!isAllowedToCorrect && paymentType === "course_payment_correction") {
            alert(t("alert.error.add_payment.not_allowed_to_correct"));
            return;
        }

        try {
            const {data: paymentData} = await ZoozaApi.post("/payments", [
                {
                    order_id,
                    amount: type === "payment" ? value : difference,
                    transaction_type: paymentType,
                    variable_symbol: order_id,
                    posting_date: moment().format("YYYY-MM-DD"),
                    value_date: moment(paymentDate).format("YYYY-MM-DD"),
                    note,
                    origin: "manual",
                    order_type: order_type === undefined ? "course" : order_type,
                },
            ]);
            if (paymentData) {
                if (onclick) {
                    onclick();
                }
                setOpen(false);
                 window.location.reload();
            }
        } catch (error: AxiosError | any) {
            handleError(error?.response.data as ErrorZozoaType, "alert");
        }
    };
    const getPayments = async () => {
        try {
            const {data: paymentsData} = await ZoozaApi.get(
                `/payments?order_id=${order_id}`
            );
            return paymentsData;
        } catch (error: AxiosError | any) {
            handleError(error?.response.data as ErrorZozoaType, "console");
        }
    };
    useEffect(() => {
        getPayments().then((res) => {
            setPayments(res.data);
        });
        if (!open) {
            setIsSubmitting(false);
        }
    }, [order_id, open]);

    useEffect(() => {
        if (type === "payment") setValue(default_value? default_value: toPay);
        if (type === "discount") setValue(alreadyDiscountPaid);
        if (type === "fee") setValue(alreadyFeePaymentPaid);
        if (type === "course_payment") setValue(alreadyCoursePaymentPaid);
    }, [payments, open]);

    useEffect(() => {
        switch (type) {
            case "discount":
                setPaymentType(
                    Number(differenceDiscountPaid) >= 0
                        ? "discount"
                        : "discount_correction"
                );
                break;
            case "fee":
                setPaymentType(
                    Number(differenceFeePaid) <= 0
                        ? "registration_fee"
                        : "registration_fee_correction"
                );
                break;

            case "course_payment":
                setPaymentType(
                    Number(differenceCoursePaid) <= 0
                        ? "course_payment"
                        : "course_payment_correction"
                );
                break;
            default:
                break;
        }
        if (type === "payment" && Number(value) < 0) {
            setPaymentType("credit_correction");
        } else if (type === "payment" && Number(value) >= 0) {
            setPaymentType(
                paymentType === "CREDIT_VIA_TRANSFER" ? "CREDIT_VIA_TRANSFER" : "credit"
            );
        }
    }, [open, value]);

    useEffect(() => {
        if (default_value) {
            setValue(default_value)
        }
    }, [default_value, open]);

    return (
        <Modal
            title={""}
            isOpen={open}
            onClose={() => setOpen(false)}
            onSubmit={onSubmit}
            submitButton={t("global.button.save")}
            cancelButton={t("global.button.cancel")}
            buttonDisabled={paymentType === "" || isSubmitting}
        >
            <form style={{display: "grid", padding: 10}} action="">
                {type === "payment" && (
                    <fieldset className="radio_group">
                        <legend>{t("global.payment.title.method")}</legend>
                        <ul>
                            <li>
                                <label htmlFor="credit">
                                    <input
                                        type="radio"
                                        name="credit"
                                        value={paymentType}
                                        checked={paymentType === "credit"}
                                        onChange={() => setPaymentType("credit")}
                                        onClick={() => setPaymentType("credit")}
                                        id="credit"
                                    />
                                    {t("modal.add_payment.select.credit")}
                                </label>
                            </li>
                            <li>
                                <input
                                    type="radio"
                                    name="CREDIT_VIA_TRANSFER"
                                    value={paymentType}
                                    checked={paymentType === "CREDIT_VIA_TRANSFER"}
                                    onChange={() => setPaymentType("CREDIT_VIA_TRANSFER")}
                                    onClick={() => setPaymentType("CREDIT_VIA_TRANSFER")}
                                    id="CREDIT_VIA_TRANSFER"
                                />
                                <label htmlFor="CREDIT_VIA_TRANSFER">
                                    {t("modal.add_payment.select.credit_via_transfer")}
                                </label>
                            </li>
                        </ul>
                    </fieldset>
                )}

                <br/>

                <>
                    <InputNumber setValue={setValue} value={value}/>
                    <br/>

                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",

                            alignItems: "center",
                        }}
                    >
                        <h4 style={{marginRight: 10}}>{t("global.title.method")}: </h4>{" "}
                        <span> {msg(paymentType)}</span>
                        <h4>
                            {t("global.title.difference")}:
                            {convertToCurrency(difference, userData?.company.region)}
                        </h4>{" "}
                    </div>
                </>

                <div
                    style={{
                        display: "flex",
                        gap: 15,

                        alignItems: "center",
                    }}
                >
                    <span>{t("global.title.date")}</span>
                    <DateTimePicker
                        setDate={setPaymentDate}
                        required={true}
                        date={paymentDate.toDate()}
                        help={""}
                    />
                </div>

                <br/>
                <div>
                    <label htmlFor="note">
                        <span>{t("global.title.note")}</span> <br/>
                        <textarea
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            id="note"
                            name="note"
                        />
                    </label>
                </div>

                <br/>
            </form>
        </Modal>
    );
};
export const InputNumber = ({
                                value,
                                setValue,
                                stepper = 1,
                                format = 4,
                                label = "",
                                disabled = false,
                                nextButtonChildreen,
                                onChangeValue,
                            }: {
    value: number | string | undefined;
    setValue?: (value: number | string | any) => void;
    stepper?: number;
    format?: number;
    label?: string | boolean;
    disabled?: boolean;
    nextButtonChildreen?: React.ReactNode;
    onChangeValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    const onStepUp = () => {
        setValue?.((Number(value) + stepper).toFixed(format));
    };
    const onStepDown = () => {
        setValue?.((Number(value) - stepper).toFixed(format));
    };
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^0-9.-]/g, ".");

        setValue?.(value);
    };

    return (
        <div className="input_number">
            {label && (
                <label>
                    <span data-bind="text: label">{label}</span>
                    <strong>
                        <abbr title="required">*</abbr>
                    </strong>
                </label>
            )}

            <div className="stepper">
                <button
                    disabled={disabled}
                    type="button"
                    id="step_up"
                    onClick={onStepUp}
                >
                    +
                </button>
                <button
                    disabled={disabled}
                    type="button"
                    id="step_down"
                    onClick={onStepDown}
                >
                    -
                </button>
                <input
                    type="text"
                    value={value}
                    id="payment_amount"
                    name="payment_amount"
                    required
                    onChange={onChangeValue ? onChangeValue : onChange}
                    disabled={disabled}
                    onBlur={(e) => {
                        setValue?.(Number(value).toFixed(format));
                    }}
                />
            </div>
            <div>{nextButtonChildreen}</div>
        </div>
    );
};

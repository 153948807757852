// AttendanceOpenCourse.tsx
import {EventDetail} from "../../types/schedule-type";
import {ZoozaCardCell, ZoozaCardRow,} from "../../components/zooza-card-components";
import {t} from "i18next";
import {Link} from "react-router-dom";
import {msg} from "../../services/common-functions";
import {AttendanceManager} from "../../components/attendance-manager";
import React, {Dispatch, SetStateAction, useContext, useEffect} from "react";
import {ZoozaApi} from "../../services/Axios";
import {EventContextTypes} from "../../types/event-context-types";
import {EventContext} from "./event-context";

export const AttendanceOpenCourse = ({
                                         event,
                                         reg,



                                     }: {
    event: EventDetail | undefined;
    reg: any;


}) => {
    const {setOpenNoVoucherModal, setOpenPaymentModal, setIds, setOpenUseVoucherModal, setVouchers, getAttendance} = useContext<EventContextTypes>(EventContext);
    const handleAddPaymentClick = () => {
        setIds({event_id: event?.id, registration_id: reg?.registration_id, user_id: reg?.user_id
    });
        setOpenPaymentModal(true);
    };
    const checkVoucher = async () => {
        setIds({event_id: event?.id, registration_id: reg?.registration_id, user_id: reg?.user_id});
        const {data} = await ZoozaApi.get(
            `/credits?types=entrance_voucher&usage=not_used&origin_user_id=${reg.user_id}&1=1`
        );
        setVouchers(data);
        if (data?.data?.some((voucher: { active: boolean }) => voucher.active)) {
            setOpenUseVoucherModal?.(true);
        } else {
            setOpenNoVoucherModal(true);
        }
    };
    useEffect(() => {

    }, []);

    return (
        <ZoozaCardRow>
            <ClientInfoCell registration={reg}/>
            <PaymentStatusCell
                paymentStatus={reg?.payment_status}
                onAddPaymentClick={handleAddPaymentClick}
            />
            <AttendanceStatusCell
                attendance={reg?.attendance}
                onSignInClick={checkVoucher}
                getAttendance={getAttendance}
            />
        </ZoozaCardRow>
    );
};

const ClientInfoCell = ({registration}: { registration: any }) => (
    <ZoozaCardCell>
    <span aria-label={t("event.title.client_name")}>
      {t("event.title.client_name")}
    </span>
        <span>
      <Link
          className="z2"
          to={`/registrations/${registration?.registration_id}`}
      >
        {registration?.full_name} <br/>
          {registration?.email} <br/>
          {registration?.phone}
      </Link>
    </span>
    </ZoozaCardCell>
);

const PaymentStatusCell = ({
                               paymentStatus,
                               onAddPaymentClick,
                           }: {
    paymentStatus: string | undefined;
    onAddPaymentClick: () => void;
}) => (
    <ZoozaCardCell>
    <span aria-label={t("global.title.payment")}>
      {t("global.title.payment")}
    </span>
        {msg(paymentStatus)}
        <div>
            <button onClick={onAddPaymentClick} className="z2 primary">
                {t("global.button.add_payment")}
            </button>
        </div>
    </ZoozaCardCell>
);

const AttendanceStatusCell = ({
                                  attendance,
                                  onSignInClick,
                                  getAttendance,
                              }: {
    attendance: any;
    onSignInClick: () => void;
    getAttendance: () => void;
}) => (
    <ZoozaCardCell>
    <span aria-label={t("global.title.attendance")}>
      {t("global.title.attendance")}
    </span>
        {!attendance?.attendance ? (
            <div>
                <button onClick={onSignInClick} className="z2 primary">
                    {t("global.button.sign_in")}
                </button>
            </div>
        ) : (
            <AttendanceManager
                registration_id={attendance?.registration_id}
                status={attendance?.attendance}
                user_id={attendance?.user_id}
                getAttendance={getAttendance}
            />
        )}
    </ZoozaCardCell>
);
